import React from "react";
import NavDesktopItemLevelPair from "./NavDesktopItemLevelPair";
import SmoothCollapse from "react-smooth-collapse";

function NavDesktopLevel({ expanded, level, levelIndex, showDeadLinks }) {
  return (
    <SmoothCollapse expanded={expanded} heightTransition="0.3s ease">
      <ul className={`mtt-fnd-level mtt-fnd-level-${levelIndex}`}>
        {level.map(item => (
          <NavDesktopItemLevelPair
            key={item.title}
            item={item}
            levelIndex={levelIndex}
            showDeadLinks={showDeadLinks}
          />
        ))}
      </ul>
    </SmoothCollapse>
  );
}

export default NavDesktopLevel;
