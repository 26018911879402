import { cloneElement, createRef, useMemo, useRef, useState } from "react";
import NavLevel from "./NavLevel";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import classNames from "classnames";
import backArrow from "../icons/Caret arrow icon - light grey.svg";
import closeX from "../icons/Close X icon.svg";
// import * as h from "../helpers";

function NavMobile({
  clearSelectedItems,
  closeNav,
  isOpen,
  isFooter,
  rootLevel,
  selectedItems,
  setLevel0SelectedItem,
  setLevel1SelectedItem,
  setLevel2SelectedItem,
  user,
}) {
  const [transition, setTransition] = useState("left");
  const navContainerRef = useRef();

  function captureClick(e) {
    e.stopPropagation();
  }

  function handleSelectedItemClick() {
    goBackALevel();
  }

  function goBackALevel() {
    const newSelected = getSecondDeepestSelectedItem();
    setSelectedItem(newSelected);
  }

  function getCurrentLevel() {
    return getDeepestSelectedItem().level;
  }

  function getDeepestSelectedItem() {
    const deepest = selectedItems.level2SelectedItem
      ? selectedItems.level2SelectedItem
      : selectedItems.level1SelectedItem
      ? selectedItems.level1SelectedItem
      : selectedItems.level0SelectedItem
      ? selectedItems.level0SelectedItem
      : rootLevel;
    return deepest;
  }

  function getSecondDeepestSelectedItem() {
    const secondDeepest = selectedItems.level2SelectedItem
      ? selectedItems.level1SelectedItem
      : selectedItems.level1SelectedItem
      ? selectedItems.level0SelectedItem
      : rootLevel;
    return secondDeepest;
  }

  function setSelectedItem(newSelected) {
    const currentLevel = getCurrentLevel();
    const toLevel = newSelected.level;
    const transition = currentLevel > toLevel ? "right" : "left";

    setTransition(transition);

    switch (toLevel) {
      case -1:
        clearSelectedItems();
        break;
      case 0:
        setLevel0SelectedItem(newSelected);
        break;
      case 1:
        setLevel1SelectedItem(newSelected);
        break;
      case 2:
        setLevel2SelectedItem(newSelected);
        break;
      default:
        return;
    }
  }

  const activeItem = getDeepestSelectedItem();
  const isInnerLevel = !!activeItem?.title;
  const transitionKey = activeItem?.title + activeItem?.level;
  const transitionName = `mtt-nav-slide-${transition}`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const transitionNodeRef = useMemo(() => createRef(), [activeItem]);
  const windowHeight = document.body.clientHeight;

  const footerActiveLevelHeight = useMemo(() => {
    // item height is based on what is set in css on .mtt-footer .mtt-nl-item (currently min-height: 3.05rem)
    const itemHeightInRem = 3.05;
    const nItems = isInnerLevel
      ? activeItem?.children.length + 1
      : activeItem?.children.length;
    return nItems * itemHeightInRem + "rem";
  }, [activeItem, isInnerLevel]);

  return (
    <div
      className={classNames({
        "mtt-nav-container-mobile": !isFooter,
        "mtt-nav-container-mobile-open": !isFooter && isOpen,
        "mtt-footer-nav-container-mobile": isFooter,
      })}
      onClick={captureClick}
      // onMouseLeave={h.getMouseLeavesVerticallyHandler(
      //   navContainerRef,
      //   closeNav
      // )}
      ref={navContainerRef}
      style={isFooter ? {} : { minHeight: windowHeight + "px" }}
    >
      <div className="mtt-nav-levels-container">
        {isInnerLevel && !isFooter ? (
          <div
            className="mtt-nav-control mtt-nav-control-back-arrow"
            onClick={goBackALevel}
          >
            <img src={backArrow} alt="Back Arrow" className="" />
          </div>
        ) : null}
        {!isFooter ? (
          <div
            className="mtt-nav-control mtt-nav-control-close-x"
            onClick={closeNav}
          >
            <img
              src={closeX}
              alt="Close X"
              className="mtt-nav-control-close-x"
            />
          </div>
        ) : null}
        <div
          className={classNames("mtt-nav-level-container-mobile")}
          style={{
            minHeight: isFooter ? footerActiveLevelHeight : "100vh",
          }}
        >
          <TransitionGroup
            // appear={true}
            exit={true}
            component={null}
            childFactory={child =>
              cloneElement(child, {
                classNames: transitionName,
              })
            }
          >
            <CSSTransition
              key={transitionKey}
              timeout={225}
              classNames={transitionName}
              nodeRef={transitionNodeRef}
            >
              {activeItem ? (
                <NavLevel
                  ref={transitionNodeRef}
                  isMobile={true}
                  level={activeItem}
                  handleSelectedItemClick={handleSelectedItemClick}
                  setSelectedItem={setSelectedItem}
                  user={user}
                />
              ) : (
                <div ref={transitionNodeRef}></div>
              )}
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
}

export default NavMobile;
