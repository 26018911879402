import { useRef, useState } from "react";

function useOffClick(handler) {
  const [offClickAdded, setOffClickAdded] = useState(false);

  // we make a ref available so that an up to date value can be available in callbacks
  const offClickAddedRef = useRef(false);

  function addOffClick() {
    // only add "off" click handler if it hasn't been added yet
    if (offClickAddedRef.current === false) {
      document.addEventListener("click", handler);
      offClickAddedRef.current = true;
      setOffClickAdded(true);
    }
  }

  function removeOffClick() {
    document.removeEventListener("click", handler);
    offClickAddedRef.current = false;
    setOffClickAdded(false);
  }

  return { offClickAdded, offClickAddedRef, addOffClick, removeOffClick };
}

export default useOffClick;
