import ActionsBar from "./ActionsBar";
import StatusBar from "./StatusBar";
import useBreakpoint from "../hooks/useBreakpoint";
import useUser from "../hooks/useUser";
import classNames from "classnames";
import * as constants from "../constants";
import * as h from "../helpers";

function Header() {
  const isMobile = useBreakpoint(constants.mobileBreakpointQuery);
  const { data: user } = useUser();
  const edgeStatus = h.getEdgeStatusFromUser(user);

  return (
    <div className={classNames({ "mtt-mobile": isMobile })}>
      <StatusBar edgeStatus={edgeStatus} isMobile={isMobile} user={user} />
      <ActionsBar isEdge={!!edgeStatus} isMobile={isMobile} />
    </div>
  );
}

export default Header;
