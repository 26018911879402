import { useRef } from "react";
import NavDesktopLevel from "./NavDesktopLevel";
import classNames from "classnames";
import closeX from "../icons/Close X icon.svg";
// import * as h from "../helpers";

function NavDesktop({
  closeNav,
  isOpen,
  rootLevel,
  selectedItems,
  setLevel0SelectedItem,
  setLevel1SelectedItem,
  setLevel2SelectedItem,
  user,
}) {
  function captureClick(e) {
    e.stopPropagation();
  }

  const navContainerRef = useRef();

  return (
    <div
      className={classNames("mtt-header-nav-desktop", {
        "mtt-header-nav-desktop-open": isOpen,
      })}
      ref={navContainerRef}
      // onMouseLeave={h.getMouseLeavesVerticallyHandler(
      //   navContainerRef,
      //   closeNav
      // )}
      onClick={captureClick}
    >
      <div className="mtt-header-nav-desktop-inner">
        <div
          className="mtt-nav-control mtt-nav-control-close-x"
          onClick={closeNav}
        >
          <img src={closeX} alt="Close X" className="mtt-nav-control-close-x" />
        </div>
        {rootLevel ? (
          <NavDesktopLevel
            level={rootLevel}
            selectedItem={selectedItems.level0SelectedItem}
            setSelectedItem={setLevel0SelectedItem}
            user={user}
          />
        ) : null}
        {selectedItems.level0SelectedItem ? (
          <NavDesktopLevel
            level={selectedItems.level0SelectedItem}
            selectedItem={selectedItems.level1SelectedItem}
            setSelectedItem={setLevel1SelectedItem}
            user={user}
          />
        ) : null}
        {selectedItems.level1SelectedItem ? (
          <NavDesktopLevel
            level={selectedItems.level1SelectedItem}
            selectedItem={selectedItems.level2SelectedItem}
            setSelectedItem={setLevel2SelectedItem}
            user={user}
          />
        ) : null}
        {selectedItems.level2SelectedItem ? (
          <NavDesktopLevel
            level={selectedItems.level2SelectedItem}
            selectedItem={{}}
            setSelectedItem={null}
            user={user}
          />
        ) : null}
      </div>
    </div>
  );
}

export default NavDesktop;
