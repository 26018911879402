import { useEffect, useRef } from "react";
import NavDesktop from "./NavDesktop";
import NavMobile from "../SharedComponents/NavMobile";
import useNavSelectedItems from "../hooks/useNavSelectedItems";
import useUser from "../hooks/useUser";

function Nav({ closeNav, isOpen, isMobile, nav }) {
  const { data: user } = useUser();
  const {
    clearSelectedItems,
    selectedItems,
    setLevel0SelectedItem,
    setLevel1SelectedItem,
    setLevel2SelectedItem,
    rootLevel,
  } = useNavSelectedItems(nav);

  const lastIsOpen = useRef(isOpen);
  useEffect(() => {
    if (isMobile && !isOpen && lastIsOpen.current !== isOpen) {
      clearSelectedItems();
    }
    if (lastIsOpen.current !== isOpen) {
      lastIsOpen.current = isOpen;
    }
  }, [clearSelectedItems, isOpen, isMobile]);

  const navProps = {
    clearSelectedItems,
    closeNav,
    isOpen,
    rootLevel,
    selectedItems,
    setLevel0SelectedItem,
    setLevel1SelectedItem,
    setLevel2SelectedItem,
    user,
  };

  return isMobile ? <NavMobile {...navProps} /> : <NavDesktop {...navProps} />;
}

export default Nav;
