import { cloneElement, createRef, useMemo } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import NavLevel from "../SharedComponents/NavLevel";

function NavDesktopLevel({ level, selectedItem, setSelectedItem, user }) {
  const transitionKey = level?.title + level?.level;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const transitionNodeRef = useMemo(() => createRef(), [level]);

  return (
    <div className="mtt-nav-level-container-desktop">
      <TransitionGroup
        appear={true}
        exit={true}
        component={null}
        childFactory={child =>
          cloneElement(child, {
            classNames: "mtt-nav-fade",
          })
        }
      >
        <CSSTransition
          key={transitionKey}
          timeout={{ enter: 200, exit: 150 }}
          classNames="mtt-nav-fade"
          nodeRef={transitionNodeRef}
        >
          <NavLevel
            ref={transitionNodeRef}
            level={level}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            user={user}
          />
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default NavDesktopLevel;
