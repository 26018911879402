import { useEffect, useRef } from "react";
import SearchAsYouTypeResult from "./SearchAsYouTypeResult";
import classNames from "classnames";

function SearchAsYouTypeResults({
  isMobile,
  keyboardHighlightIndex,
  moreResultsAvailable,
  searchReturnedNoResults,
  searchResults,
  searchTermForCurrentResults,
  submitSearch,
}) {
  const resultsContainer = useRef();

  useEffect(() => {
    // scroll to bottom of results container (to reveal "Show All" button)
    // when last result is keyboard highlighted
    if (keyboardHighlightIndex === searchResults.length - 1) {
      resultsContainer.current.scrollTop += 100;
    }
  }, [keyboardHighlightIndex, searchResults]);

  // componentDidUpdate(prevProps, prevState) {
  //   // scroll to bottom of results container (to reveal "Show All" button)
  //   // when last result is keyboard highlighted
  //   if (
  //     prevProps.keyboardHighlightIndex !== keyboardHighlightIndex &&
  //     keyboardHighlightIndex === searchResults.length - 1
  //   ) {
  //     resultsContainer.current.scrollTop += 100;
  //   }
  //   // TODO: fix scrolling for mobile when scroll back to top
  //   // -- currently doesn't show search field again
  //   // if (
  //   //   prevProps.keyboardHighlightIndex !== keyboardHighlightIndex &&
  //   //   keyboardHighlightIndex === 0
  //   // ) {
  //   //   this.resultsContainer.scrollTop -= 300;
  //   // }
  // }
  return (
    <div
      className={classNames("mt-search-ayt-container-outer", {
        "mt-search-ayt-container-outer-mobile": isMobile,
      })}
      ref={resultsContainer}
    >
      <div className="mt-search-ayt-container">
        {searchReturnedNoResults && (
          <div className="mt-search-ayt-no-matches-container">
            No matches found for{" "}
            <span className="mt-search-ayt-no-matches-term">
              "{searchTermForCurrentResults}"
            </span>
          </div>
        )}
        {!searchReturnedNoResults &&
          searchResults.map((res, index) => {
            return (
              <SearchAsYouTypeResult
                key={res.title + index}
                index={index}
                keyboardHighlightIndex={keyboardHighlightIndex}
                result={res}
              />
            );
          })}
        {moreResultsAvailable && (
          <div className="mt-search-ayt-show-all-container">
            <div className="button button-small" onClick={submitSearch}>
              Show All
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchAsYouTypeResults;
