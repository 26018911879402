import { useState } from "react";
import * as h from "../helpers";
import classNames from "classnames";

function StatusBar({ edgeStatus, isMobile, user }) {
  const [userActionsOpen, setUserActionsOpen] = useState(false);
  const [userActionsOffClickAdded, setUserActionsOffClickAdded] =
    useState(false);

  const userInitials = h.getUserInitials(user);

  function addUserActionsOffClick() {
    const userActionsOffClick = () => {
      setUserActionsOpen(false);
      document.removeEventListener("click", userActionsOffClick);
      setUserActionsOffClickAdded(false);
    };

    // only add "off" click handler if it hasn't been added yet
    if (userActionsOffClickAdded === false) {
      document.addEventListener("click", userActionsOffClick);
      setUserActionsOffClickAdded(true);
    }
  }

  function handleUserMenuButtonClick(e) {
    if (!userActionsOpen) {
      addUserActionsOffClick();
      setUserActionsOpen(true);
      e.stopPropagation();
    }
  }

  return (
    <div
      className={classNames("mtt-header-status-bar mt-text-small", {
        "mtt-hsb-mobile": isMobile,
      })}
    >
      {user?.fullname ? (
        <>
          <div className="mtt-hsb-initials-container">{userInitials}</div>
          <div
            className="mtt-hsb-user-actions-container"
            onClick={handleUserMenuButtonClick}
            role="button"
            aria-pressed={userActionsOpen}
          >
            <span className="mtt-hsb-greeting">Hi, {user.fullname}</span>
            <div
              className={classNames("mtt-hsb-user-actions", {
                "mtt-hsb-user-actions-open": userActionsOpen,
              })}
            >
              <ul>
                {user.user_actions.map(action => (
                  <li key={action.title}>
                    <a href={action.url}>{action.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      ) : null}
      {user?.dealer?.account_code && !isMobile ? (
        <>
          <div className="mtt-hsb-divider"></div>
          Acct: {user.dealer.account_code}
        </>
      ) : null}
      {edgeStatus ? (
        <>
          {!isMobile ? <div className="mtt-hsb-divider"></div> : null}
          <img
            className={classNames("mtt-hsb-edge-status-icon", {
              "mtt-hsb-edge-status-icon-mobile": isMobile,
            })}
            src={edgeStatus.whiteIcon}
            alt={`Edge ${edgeStatus.name} Icon`}
          />
        </>
      ) : null}
    </div>
  );
}

export default StatusBar;
