import { useCallback, useRef, useEffect, useState } from "react";
import SearchInput from "./SearchInput";
import SearchAsYouTypeResults from "./SearchAsYouTypeResults";
import closeIcon from "../icons/Close X icon.svg";
import searchIcon from "../icons/Search Icon.svg";
import axios from "axios";
import classNames from "classnames";
import debounce from "lodash.debounce";
import throttle from "lodash.throttle";
import * as constants from "../constants";
import * as h from "../helpers";
import "../styles/searchAsYouType.css";

function SearchAsYouType({ isMobile }) {
  const [keyboardHighlightIndex, setKeyboardHighlightIndex] = useState("");

  const searchCache = useRef({});
  const searchIndex = useRef(0);
  const mostRecentSearchReturnedIndex = useRef(-1);
  const searchInputRef = useRef();

  const [searchOffClickAdded, setSearchOffClickAdded] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchReturned, setSearchReturned] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermForCurrentResults, setSearchTermForCurrentResults] =
    useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const performSearchDebounced = useCallback(debounce(performSearch, 500), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const performSearchThrottled = useCallback(throttle(performSearch, 500), []);

  // We create a ref with the current searchTerm to make it available in enclosed
  // callbacks so that we don't have to recreate (and thus make useless) the
  // callback functions generated by throttle and debounce each time searchTerm changes
  const callbackSearchTerm = useRef(searchTerm);
  callbackSearchTerm.current = searchTerm;

  useEffect(() => {
    if (searchTerm.length < 5 || h.lastChar(searchTerm) === " ") {
      performSearchThrottled(searchTerm);
    } else {
      performSearchDebounced(searchTerm);
    }
  }, [searchTerm, performSearchDebounced, performSearchThrottled]);

  const addSearchOffClick = useCallback(() => {
    // only add "off" click handler if it hasn't been added yet
    if (searchOffClickAdded === false) {
      document.addEventListener("click", closeSearch);
      setSearchOffClickAdded(true);
    }
  }, [searchOffClickAdded]);

  const removeSearchOffClick = useCallback(() => {
    document.removeEventListener("click", closeSearch);
    setSearchOffClickAdded(false);
  }, []);

  useEffect(() => {
    if (searchReturned && isSearchTermValid(searchTerm)) {
      addSearchOffClick();
    } else if (searchReturned && searchTerm.trim() === "") {
      removeSearchOffClick();
    }
  }, [
    addSearchOffClick,
    removeSearchOffClick,
    searchResults,
    searchTerm,
    searchReturned,
  ]);

  function clearSearch() {
    closeSearch();
    searchInputRef.current.focus();
  }
  function closeSearch() {
    // if (this.state.searchOpen) {
    //   this.removeDesktopSearchOffClick();
    //   this.setState({ searchOpen: false, searchTerm: "" });
    // }
    setSearchTerm("");
  }

  function handleClickInsideSearch(e) {
    e.nativeEvent.stopImmediatePropagation();
  }

  function handleInput(e) {
    setSearchTerm(e.target.value);
    setKeyboardHighlightIndex(null);
  }

  function handleKeydown(limit, enterKeyCallback, e) {
    const key = e.key;
    const currIndex = keyboardHighlightIndex;

    if (key === "ArrowDown" || key === "Down" || key === "Tab") {
      e.preventDefault();
      // Start highlight at first result on first arrow down
      // Don't respond to arrow down when at last result
      const alreadyAtLimit = currIndex === limit;
      const newIndex =
        currIndex === null ? 0 : alreadyAtLimit ? limit : currIndex + 1;
      // Scroll to reveal "Show All" button when pressing down arrow in mobile view
      if (isMobile && !alreadyAtLimit && newIndex === limit) {
        window.scrollBy(0, 145);
      }
      // this.setState({ searchResultsKeyboardHighlightIndex: newIndex });
      setKeyboardHighlightIndex(newIndex);
    } else if (key === "ArrowUp" || key === "Up") {
      e.preventDefault();
      // Don't respond to arrow up when no result is highlighted
      const newIndex =
        currIndex === 0 || currIndex === null ? null : currIndex - 1;
      // Scroll to reveal search input and close x when pressing up arrow in mobile view
      if (currIndex === 0 && isMobile) {
        window.scrollTo(0, 0);
      }
      // this.setState({ searchResultsKeyboardHighlightIndex: newIndex });
      setKeyboardHighlightIndex(newIndex);
    } else if (key === "Escape") {
      closeSearch();
    } else if (key === "Enter") {
      // If result is highlighted take to that result's url
      // If no result highlighted let fall through to default submit search behavior
      if (currIndex !== null) {
        e.preventDefault();
        enterKeyCallback();
      }
    }
  }

  function handleKeyboardHighlightEnterKey() {
    if (keyboardHighlightIndex !== null) {
      const result = searchResults[keyboardHighlightIndex];
      const linkUrl = h.getLinkUrlFromResult(result);
      window.location.assign(linkUrl);
    }
  }

  function handleSearchIconClick() {
    if (isSearchTermValid(searchTerm)) {
      submitSearch();
    }
  }

  function isSearchTermValid(term) {
    return (
      term.trim() !== "" && term.trim().length >= constants.minimumSearchLength
    );
  }

  function keyboardHighlightIndexLimit() {
    return visibleResults().length - 1;
  }

  function moreResultsAvailable() {
    return searchResults.length > constants.searchResultsLimit;
  }

  // All functions called in this function are stable, in that they receive all their variables
  // as arguments, or they reference them as [refName].current, so that it can be passed
  // to throttle and debounce as is once, and not need to be changed as the component's state updates.
  // To make all functions callbacks here would be a little too much hoop jumping.
  // Be sure to make any changes in this function follow the same pattern of stable functions/ref variables.
  function performSearch(searchTerm) {
    const trimmedTerm = searchTerm.trim();

    // To prevent showing an outdated search that returns later than the most recent search
    // we increment a search index and create a scoped variable based on it for this function call.
    // When a search returns and is fresh we update the mostRecentSearchReturnedIndex,
    // which is how we determine if a search that returns is fresh.
    searchIndex.current += 1;
    const scopedIndex = searchIndex.current;

    if (isSearchTermValid(trimmedTerm)) {
      const cached = searchCache.current[trimmedTerm];
      if (cached) {
        updateSearchResults(cached, scopedIndex, trimmedTerm, true);
      } else {
        axios.get(`${constants.searchEndpoint}?q=${trimmedTerm}`).then(
          res => {
            // update cached results regardless
            updateCache(res.data, trimmedTerm);
            // only update display results if a more recent search hasn't returned yet
            // and the current search term isn't empty (happens sometimes with combination of debounce and throttle)
            if (
              returnedResultsAreFresh(scopedIndex) &&
              !searchTermIsCurrentlyEmpty()
            ) {
              // For testing no results returned:
              // const data = scopedIndex % 2 === 0 ? res.data : [];
              updateSearchResults(
                // data,
                res.data,
                scopedIndex,
                trimmedTerm,
                true
              );
            }
          },
          err => {
            console.log("error:", err.message);
          }
        );
      }
    } else if (trimmedTerm === "") {
      updateSearchResults([], scopedIndex, trimmedTerm, false);
    }
  }

  function returnedResultsAreFresh(searchIndex) {
    return mostRecentSearchReturnedIndex.current < searchIndex;
  }

  function searchReturnedNoResults() {
    if (
      searchReturned &&
      searchResults.length === 0 &&
      isSearchTermValid(searchTerm)
    ) {
      return true;
    }
    return false;
  }

  function searchTermIsCurrentlyEmpty() {
    return callbackSearchTerm.current.trim() === "";
  }

  function submitSearch(e) {
    const searchValue = searchTerm.trim();
    window.location.assign(`/search?SearchableText=${searchValue}`);
  }

  function updateCache(results, searchTerm) {
    searchCache.current[searchTerm] = results;
  }

  function updateSearchResults(results, index, searchTerm, searchReturned) {
    mostRecentSearchReturnedIndex.current = index;
    setSearchResults(results);
    setSearchReturned(searchReturned);
    setSearchTermForCurrentResults(searchTerm);
  }

  function visibleResults() {
    // Return only results up to limit defined in constants
    return searchResults.slice(0, constants.searchResultsLimit);
  }

  const closeIconClasses = classNames("mtt-hab-search-close-icon", {});

  const formClasses = classNames("mtt-hab-search-form", {});

  const searchResultsComponent = (
    <SearchAsYouTypeResults
      isMobile={isMobile}
      moreResultsAvailable={moreResultsAvailable()}
      keyboardHighlightIndex={keyboardHighlightIndex}
      searchReturnedNoResults={searchReturnedNoResults()}
      searchResults={visibleResults()}
      searchTermForCurrentResults={searchTermForCurrentResults}
      submitSearch={submitSearch}
    />
  );

  return (
    <>
      <form
        action="/search"
        className={formClasses}
        autoComplete="off"
        onClick={handleClickInsideSearch}
      >
        <SearchInput
          handleInput={handleInput}
          handleKeydown={handleKeydown.bind(
            null,
            keyboardHighlightIndexLimit(),
            handleKeyboardHighlightEnterKey
          )}
          isMobile={isMobile}
          refFunc={searchInputRef}
          value={searchTerm}
        />
        {searchTerm !== "" && (
          <img
            src={closeIcon}
            alt="search close icon"
            className={closeIconClasses}
            onClick={clearSearch}
          />
        )}
        <img
          src={searchIcon}
          alt="search icon"
          className={classNames("mtt-hab-search-bar-icon", {
            "mtt-hab-search-bar-icon-clickable": isSearchTermValid(searchTerm),
          })}
          onClick={handleSearchIconClick}
        />
        {searchReturned && searchResults && searchResultsComponent}
      </form>
      {/* {isMobile && (
          <>
            <div className="mthms-search-form-underline" />
            {searchReturned && searchResultsComponent}
          </>
        )} */}
    </>
  );
}

export default SearchAsYouType;
