import { useEffect, useRef } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import scrollIntoView from "scroll-into-view-if-needed";
import * as h from "../helpers";

function SearchAsYouTypeResult({ keyboardHighlightIndex, index, result }) {
  // constructor(props) {
  //   super(props);

  //   this.resultContainer = null;
  //   this.setResultContainerRef = el => {
  //     this.resultContainer = el;
  //   };
  // }
  const resultContainer = useRef();

  useEffect(() => {
    if (keyboardHighlightIndex === index) {
      scrollIntoView(resultContainer.current, {
        block: "nearest",
        inline: "nearest",
        scrollMode: "if-needed",
      });
    }
  }, [keyboardHighlightIndex, index]);

  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     prevProps.keyboardHighlightIndex !== keyboardHighlightIndex &&
  //     keyboardHighlightIndex === index
  //   ) {
  //     scrollIntoView(this.resultContainer, {
  //       block: "nearest",
  //       inline: "nearest",
  //       scrollMode: "if-needed",
  //     });
  //   }
  // }

  function createLocationMessage(path) {
    const locations = path.split("/");
    const nonRootLocations = locations.slice(2);
    const trimmedPath = "/" + nonRootLocations.join("/\u200b");
    // return <div>Located in {trimmedPath}</div>;
    return <div>&nbsp;{trimmedPath}</div>;
  }

  function handleSearchResultClick(result) {
    const linkUrl = h.getLinkUrlFromResult(result);
    window.location.assign(linkUrl);
  }

  function formatAsMediumDate(dateString) {
    const dateOnly = dateString.slice(0, 10);
    const formatted = dayjs(dateOnly).format("MMM D, YYYY");
    return formatted;
  }

  const location = createLocationMessage(result.path);
  const titleClasses = classNames("mt-search-ayt-result-title mt-text-normal", {
    "mt-search-ayt-state-private": result.review_state === "private",
  });
  const publishedDate = formatAsMediumDate(result.effective_date);
  const resultClasses = classNames("mt-search-ayt-result", {
    "mt-search-ayt-result-keyboard-highlight": keyboardHighlightIndex === index,
  });
  return (
    <div
      className={resultClasses}
      onClick={handleSearchResultClick.bind(null, result)}
      ref={resultContainer}
    >
      <div className={titleClasses}>{result.title}</div>
      <div className="mt-search-ayt-result-date mt-text-small">
        &nbsp;— published {publishedDate}
      </div>
      <div className="mt-text-small">{location}</div>
    </div>
  );
}

export default SearchAsYouTypeResult;
