import NavDesktop from "./NavDesktop";
import NavMobile from "../SharedComponents/NavMobile";
import useNavSelectedItems from "../hooks/useNavSelectedItems";
import useNav from "../hooks/useNav";
import useUser from "../hooks/useUser";

function Nav({ isMobile }) {
  const { data: nav } = useNav();
  const { data: user } = useUser();
  const selectedItemsObj = useNavSelectedItems(nav);

  return isMobile ? (
    <NavMobile {...selectedItemsObj} isFooter={true} user={user} />
  ) : (
    <NavDesktop nav={nav} user={user} />
  );
}

export default Nav;
