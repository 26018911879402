import * as constants from "./constants";

export const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const firstWithChild = items => {
  return items.filter(item => hasChildren(item))[0];
};

export function getEdgeStatusFromUser(user) {
  if (!user?.dealer?.edge) {
    return false;
  }
  const status = constants.edgeStatuses.find(
    status => user.dealer[status.prop_name] === true
  );
  return status ? status : false;
}

export function getWhiteEdgeIconFromUser(user) {
  const status = getEdgeStatusFromUser(user);
  return status ? status.whiteIcon : null;
}

export function getUserInitials(user) {
  if (!user?.fullname) {
    return "";
  }
  const names = user.fullname.split(" ");
  const firstInitial = names[0].charAt(0);
  const lastInitial = names.length > 1 ? names[names.length - 1].charAt(0) : "";
  return firstInitial + lastInitial;
}

export const hasChildren = item => {
  return item && item.children && item.children.length > 0;
};

export const lastChar = str => {
  return str.charAt(str.length - 1);
};

export const someHaveChildren = items => {
  return items.filter(item => hasChildren(item)).length > 0;
};

export const getLinkUrlFromResult = result => {
  return result.remote_url ? result.remote_url : result.object_url;
};

export function getMouseLeavesVerticallyHandler(containerRef, cb) {
  return e => {
    const mouseExitY = e.clientY;
    const rect = containerRef.current.getBoundingClientRect();

    // call cb function only when mouse leaves through top or bottom of container
    if (mouseExitY >= rect.y + rect.height || mouseExitY <= rect.y) {
      cb();
    }
  };
}

export function openNewWindowIfPossible(url) {
  // in case the user has an aggressive pop-up blocker,
  // fall back to changing window url when window.open() returns false
  window.open(url, "_blank") || window.location.assign(url);
}
