import { forwardRef } from "react";
import { useMutation } from "@tanstack/react-query";
import caret from "../icons/Caret arrow icon - light grey.svg";
// import caretWhite from "../icons/Caret arrow icon - white.svg";
// import caret from "../icons/Caret arrow icon - black.svg";
import classNames from "classnames";
import * as constants from "../constants";
import * as h from "../helpers";
import "./navLevel.css";

const NavLevel = forwardRef(
  (
    {
      handleSelectedItemClick,
      isMobile,
      level,
      selectedItem,
      setSelectedItem,
      user,
    },
    ref
  ) => {
    const isInnerLevel = !!level?.title;

    const toggleShowDeadLinksMutation = useMutation([
      constants.toggleShowDeadLinksMutationName,
    ]);

    const showDeadLinks = user?.show_dead_links;

    function captureClick(e) {
      e.stopPropagation();
    }

    function handleItemClick(item, e) {
      const isSelected = selectedItem?.title === item.title;
      const goToUrl = isSelected && showDeadLinks;
      if (h.hasChildren(item) && !goToUrl) {
        setSelectedItem(item);
      } else if (item.new_window) {
        e.preventDefault();
        h.openNewWindowIfPossible(item.url);
      } else {
        window.location.assign(item.url);
      }
    }

    return (
      <ul className="mtt-nav-level" onClick={captureClick} ref={ref}>
        {isInnerLevel ? (
          isMobile ? (
            <li
              className="mtt-nl-item mtt-nl-category"
              onClick={handleSelectedItemClick}
            >
              {level.title}
            </li>
          ) : (
            <li className="mtt-nl-item mtt-nl-category">{level.title}</li>
          )
        ) : null}
        {level.children.map(item => {
          const isSelected = selectedItem?.title === item.title;
          const noneSelected = !selectedItem;
          const hasChildren = h.hasChildren(item);
          return (
            <li
              className={classNames("mtt-nl-item", item.review_state, {
                "mtt-nl-item-root-level": !isInnerLevel,
                "mtt-nl-item-none-selected": noneSelected,
                "mtt-nl-item-selected": isSelected,
                "mtt-nl-item-hidden":
                  !showDeadLinks && item.review_state === "private",
              })}
              onClick={e => handleItemClick(item, e)}
              key={item.title + item.level + item.order_no}
            >
              {hasChildren ? (
                item.title
              ) : (
                <a
                  href={item.url}
                  target={item.new_window ? "_blank" : "_self"}
                >
                  {item.title}
                </a>
              )}
              {hasChildren ? <img src={caret} alt="" /> : null}
            </li>
          );
        })}
        {!isInnerLevel && user && user.show_dead_links !== undefined ? (
          <li
            className="mtt-nl-show-links-item"
            onClick={() => {
              if (!toggleShowDeadLinksMutation.isLoading) {
                toggleShowDeadLinksMutation.mutate(showDeadLinks);
              }
            }}
          >
            {`Admin: ${showDeadLinks ? "Hide" : "Show"} inactive links`}
          </li>
        ) : null}
      </ul>
    );
  }
);

export default NavLevel;
