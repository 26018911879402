import React from "react";
import classNames from "classnames";
import NavDesktopItemLevelPair from "./NavDesktopItemLevelPair";

function NavDesktop({ nav, user }) {
  const showDeadLinks = user?.show_dead_links;

  return (
    <div className="mtt-footer-nav-desktop">
      {nav?.map(category =>
        !showDeadLinks && category.review_state === "private" ? null : (
          <ul className="mtt-fnd-category-container" key={category.title}>
            <li
              className={classNames(
                "mt-text-large mtt-fnd-category-title",
                category.review_state
              )}
            >
              {category.title}
            </li>
            {category.children.map(item => {
              return (
                <NavDesktopItemLevelPair
                  key={item.title}
                  item={item}
                  levelIndex={1}
                  showDeadLinks={showDeadLinks}
                />
              );
            })}
          </ul>
        )
      )}
    </div>
  );
}

export default NavDesktop;
