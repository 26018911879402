import { useRef, useState } from "react";
import Nav from "./Nav";
// import bullhorn from "../icons/Announcements bullhorn icon.svg";
import edgeLogo from "../icons/edge_logo_white.svg";
import menuIcon from "../icons/White Menu Icon.svg";
import MTDesktopLogo from "../icons/mohawk_today_lockup_white_logo.svg";
import MTMobileLogo from "../icons/mohawk_today_white_logo.svg";
import SearchAsYouType from "./SearchAsYouType";
import classNames from "classnames";
import useOffClick from "../hooks/useOffClick";
import useNav from "../hooks/useNav";
import * as constants from "../constants";

function ActionsBar({ isEdge, isMobile }) {
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [mouseEnterDelayedHandlerTimeout, setMouseEnterDelayedHandlerTimeout] =
    useState(null);
  const {
    addOffClick: addNavOffClick,
    offClickAddedRef: navOffClickAddedRef,
    removeOffClick: removeNavOffClick,
  } = useOffClick(closeNav);
  const { data: nav } = useNav();

  const menuIconContainerRef = useRef();

  function closeNav() {
    setNavIsOpen(false);
    removeNavOffClick();
  }

  function handleMenuIconContainerClick(e) {
    if (!navOffClickAddedRef.current) {
      setNavIsOpen(true);
      e.preventDefault();
      e.stopPropagation();
      addNavOffClick();
    }
  }

  // function handleMenuIconContainerMouseEnter(e) {
  //   setMouseEnterDelayedHandlerTimeout(
  //     setTimeout(() => {
  //       if (!navOffClickAddedRef.current) {
  //         setNavIsOpen(true);
  //         addNavOffClick();
  //         setMouseEnterDelayedHandlerTimeout(null);
  //       }
  //     }, 200)
  //   );
  // }

  // function handleMenuIconContainerMouseLeave(e) {
  //   // if delay time hasn't passed, clear the timeout and do nothing
  //   if (mouseEnterDelayedHandlerTimeout) {
  //     clearTimeout(mouseEnterDelayedHandlerTimeout);
  //     setMouseEnterDelayedHandlerTimeout(null);
  //     return;
  //   }

  //   const mouseExitBuffer = isMobile ? 9 : 24;
  //   const mouseExitY = e.clientY + mouseExitBuffer;
  //   const rect = menuIconContainerRef.current.getBoundingClientRect();

  //   // do nothing if mouse leaves through bottom edge of menu icon or if off click hasn't been added
  //   if (mouseExitY >= rect.y + rect.height || !navOffClickAddedRef.current) {
  //     return;
  //   }

  //   closeNav();
  // }

  return (
    <div
      className={classNames("mtt-header-actions-bar", {
        "mtt-hab-mobile": isMobile,
      })}
    >
      <div
        className="mtt-hab-menu-icon-container"
        ref={menuIconContainerRef}
        onClick={handleMenuIconContainerClick}
        // onMouseEnter={handleMenuIconContainerMouseEnter}
        // onMouseLeave={handleMenuIconContainerMouseLeave}
      >
        <img src={menuIcon} className="mtt-hab-menu-icon" alt="" />
      </div>
      <a href={constants.MTLogoUrl}>
        <img
          src={isMobile ? MTMobileLogo : MTDesktopLogo}
          alt=""
          className="mtt-hab-logo"
        />
      </a>
      {!isMobile ? (
        <div className="mtt-hab-search-bar">
          <SearchAsYouType isMobile={isMobile} />
        </div>
      ) : null}
      <div className="mtt-hab-actions-group">
        <a
          className={"button button-small button-edge-icon"}
          href={
            isEdge
              ? "https://leads.mohawktoday.com/Leads.aspx"
              : "https://mohawktoday.com/edge"
          }
          style={isEdge ? {} : { width: isMobile ? "3.44rem" : "4.72rem" }}
        >
          {isEdge ? (
            "Leads"
          ) : (
            <img
              src={edgeLogo}
              alt="Edge"
              style={{ height: isMobile ? "1.14rem" : "1.416rem" }}
            />
          )}
        </a>
        {!isMobile ? (
          <a
            className="button button-small button-small-icon"
            href={constants.FAQUrl}
            title="FAQ"
          >
            <span className="mtt-faq-icon">?</span>
          </a>
        ) : null}
      </div>
      {isMobile ? (
        <div className="mtt-hab-search-bar mtt-hab-search-bar-mobile">
          <SearchAsYouType isMobile={isMobile} />
        </div>
      ) : null}
      <Nav
        nav={nav}
        closeNav={closeNav}
        isOpen={navIsOpen}
        isMobile={isMobile}
      />{" "}
    </div>
  );
}

export default ActionsBar;
